import './Product.scss';
import {useCallback, useEffect, useState} from "react";
import ky from "ky";
import {useCookies} from "react-cookie";
import ProductImages from "./ProductImages";
import moment from "moment";

function Product(props) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    const product_id = props.product_id;
    const [cookies] = useCookies(['userId']);
    const [product, setProduct] = useState(null);
    const [attributes, setAttributes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [descCollapsed, setDescCollapsed] = useState(true);

    function buildAttributes(product) {
        const SIZE_NAMES = ['XXXS', 'XXS', 'X-Small', 'Small', 'Medium', 'Large', 'X-Large', 'XXL', 'XXXL'];
        let attrs = [];
        // Sizes
        let numeric_size = '';
        if (product.annotation_size_min_numeric && product.annotation_size_max_numeric
            && product.annotation_size_max_numeric - product.annotation_size_min_numeric < 4) {
            numeric_size = `${product.annotation_size_min_numeric} - ${product.annotation_size_max_numeric}`;
            if (product.annotation_size_min_numeric === product.annotation_size_max_numeric) {
                numeric_size = product.annotation_size_min_numeric;
            }
        }
        let symbolic_size = '';
        if (product.annotation_size_min_symbolic && product.annotation_size_max_symbolic
            && product.annotation_size_max_symbolic - product.annotation_size_min_symbolic < 3) {
            symbolic_size = `${SIZE_NAMES[product.annotation_size_min_symbolic]} - ${SIZE_NAMES[product.annotation_size_max_symbolic]}`;
            if (product.annotation_size_min_symbolic === product.annotation_size_max_symbolic) {
                symbolic_size = SIZE_NAMES[product.annotation_size_min_symbolic];
            }
        }
        if (symbolic_size || numeric_size) {
            attrs.push({name: "Size", value: (symbolic_size + ' ' + numeric_size).trim()});
        }

        // Decade
        if (product.annotation_decade && product.annotation_decade.toLowerCase() !== 'unknown') {
            attrs.push({name: "Decade", value: product.annotation_decade});
        }

        // Date
        if (product.annotation_date_first_added && product.annotation_date_first_added !== '') {
            const date = Date.parse(product.annotation_date_first_added);
            if (date) {
                attrs.push({name: "Listed", value: moment(date).fromNow()});
            }
        }
        return attrs;
    }

    const loadProduct = useCallback(async () => {
        let response = null;
        try {
            await setLoading(true);
            await setError(false);
            response = await ky.get(`https://api.vintag.io/product?id=${product_id}`, {
                headers: {
                    'x-vintagio-session-id': cookies.userId
                }}).json();
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(err);
            window.location.replace("/404");
        }
        if (response) {
            setProduct(response);
            setAttributes(buildAttributes(response));
        }
    }, []);

    useEffect(() => {
        if (!product) {
            loadProduct();
        }
    }, [product, product_id, loadProduct]);

    function logClick(product) {
        ky.get('https://api.vintag.io/log?event=buy_now_click&id='+product.id, {
            headers: {
                'x-vintagio-session-id': cookies.userId
            }});
        gtag('event', 'conversion', {
            'send_to': 'AW-862812814/E5kHCNvn-MQDEI71tZsD',
            'event_callback': function() {}
        });
        gtag('event', 'conversion', {
            'send_to': 'G-Y8ZMS72663',
            'event_callback': function() {}
        });
    }

    return (
        <>
           <div className={"ProductImageContainer"}>
               <ProductImages images={product ? product.images : []} productId={product_id} />
           </div>
            <div className={"ProductDetailsContainer"}>
                <h2>{product ? product.name : "Loading..."}</h2>
                <div className={"ProductPrice"}>{product ? product.formatted_price : '$--.--'}</div>
                <div onClick={product ? () => {logClick(product);} : () => {}} className={"Buy-Container"}>
                    <a href={product ? product.url : '#'} >Buy It Now <span className={"divider"}>|</span> <span>{product ? product.platform_id : ''}</span></a>
                </div>
                <div className={"ProductAttrsContainer"}>
                    <ul>
                        {attributes.map((attr, index) => (
                            <li key={index} className={"cle"}>
                                <span className={"AttrLabel"}>{attr.name}</span>
                                <span className={"Attr"}>{attr.value}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={"ProductDetails"}>
                    <h6>Description</h6>
                    <div className={`ProductDescription ${descCollapsed ? 'collapsed' : ''}`}>
                        {product ? product.description.replaceAll("</p><p>", "\n\n").replace(/<\/?[^>]+(>|$)/g, "") : ""}
                        {descCollapsed && (<button onClick={() => { setDescCollapsed(false); }}>
                            Read More
                        </button>)}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Product;
