
export function getParentCategory(taxonomy, child_id) {
    for (const parent of Object.values(taxonomy)) {
        if (!parent.children) {
            continue;
        }
        for (const child of Object.values(parent.children)) {
            if (child.id === child_id) {
                return parent
            }
        }
    }
    return null;
}

export function genderFromURL() {
    if (window.location.pathname.indexOf('/women/') === 0) {
        return 'women'
    }
    if (window.location.pathname.indexOf('/men/') === 0) {
        return 'men'
    }
    return 'unisex'
}