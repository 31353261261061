import './ProductList.scss';
import FixedSizeProduct from "./FixedSizeProduct";

function ProductList(props) {
    return (
        <div className={"ProductList-container"}>
            {props.products.map((product, index) => (
                <FixedSizeProduct key={index} index={index} product={product} />
            ))}
            {props.resultCount == 0 && (
                <div className={"ProductList-no-results-container"}>
                    <h2>Bummer! We couldn't find any results.</h2>
                    <p>Try removing some filters or searching again.</p>
                </div>
            )}
            <div className={`loading-container header-width ${props.isLoading ? 'visible' : ''}`}>
                <img className={"loading-animation"} src={'/imgs/loading.gif'} />
            </div>
        </div>
    );
}

export default ProductList;
