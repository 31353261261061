import Hero from "../components/Hero";
import Carousel from "../components/Carousel";
import SearchSuggestions from "../components/SearchSuggestions";
import Header from "../components/Header";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

function Home() {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    const location = useLocation()
    useEffect(() => {
        gtag('event', 'page_view', {
            page_title: document.title,
            page_location: window.location.pathname,
            page_path: window.location.search,
        });
    }, [location]);

    return (
        <>
            <Header isHero={true} />
            <Hero />
            <Carousel title={"Recently Added"} baseUrl={"https://api.vintag.io/recent?sort=rand&limit=12&nolog=1"} seeAllUrl={"/unisex/recent"} />
            <Carousel title={"Staff Picks"} baseUrl={"https://api.vintag.io/staff_picks?gender=women&limit=12&nolog=1"} seeAllUrl={"/unisex/staff"} />
            <br />
            <SearchSuggestions />
            <Carousel title={"90s Sweatshirts"} baseUrl={"https://api.vintag.io/category?category_id=sweatshirts-pullovers&gender=men&decades=1990&limit=12&nolog=1"} seeAllUrl={"/men/sweatshirts-pullovers?decades=1990"} />
            <Carousel title={"Denim Jackets"} baseUrl={"https://api.vintag.io/category?category_id=denim-jackets&gender=women&limit=12&nolog=1"} seeAllUrl={"/women/denim-jackets"} />
            <Carousel title={"Adidas Tracksuits"} baseUrl={"https://api.vintag.io/search?q=adidas%20tracksuit&limit=12&nolog=1"} seeAllUrl={"/search?q=adidas+tracksuit"} />
            <Carousel title={"High-End Handbags"} baseUrl={"https://api.vintag.io/category?category_id=handbags-purses&gender=women&price_min=300&limit=12&nolog=1"} seeAllUrl={"/women/handbags-purses?price_min=300"} />
            <Carousel title={"Vintage Tees"} baseUrl={"https://api.vintag.io/category?category_id=tees&gender=men&decades=1960,1970&limit=12&nolog=1"} seeAllUrl={"/men/tees?decades=1960%2C1970"} />
            <Carousel title={"Flannel"} baseUrl={"https://api.vintag.io/category?category_id=flannel-shirts&gender=men&limit=12&nolog=1"} seeAllUrl={"/men/flannel-shirts"} />
            <Carousel title={"Dr. Martens"} baseUrl={"https://api.vintag.io/search?&q=Dr.%20Martens&gender=women&limit=12&nolog=1"} seeAllUrl={"/women/brand/Dr.%20Martens"} />
            <br />
        </>
    );
}

export default Home;



