import './Carousel.scss';
import FixedSizeProduct from "./FixedSizeProduct";
import React, { useEffect, useState, useRef } from 'react';
import ky from 'ky';
import {useCookies} from "react-cookie";
import left_chevron from "../svg/chevron_left.svg";
import right_chevron from "../svg/chevron_right.svg";

function Carousel(props) {
    const [cookies] = useCookies(['userId']);
    const [productInfo, setProductInfo] = useState([{}, {}, {}, {}, {}, {}, {}, {}]);
    const [leftArrowHidden, setLeftArrowHidden] = useState(true);
    const [rightArrowHidden, setRightArrowHidden] = useState(false);
    const title = props.title;
    const baseUrl = props.baseUrl;
    const scrollContainer = useRef(null);
    useEffect(() => {
        async function loadProducts() {
            let response = await ky.get(baseUrl, {
                headers: {
                'x-vintagio-session-id': cookies.userId
            }}).json();
            setProductInfo(response.items);
        }
        loadProducts();
    }, [baseUrl]);

    useEffect(() => {
        function scrollListener(e) {
            let scrollMax = scrollContainer.current.scrollMaxX || (scrollContainer.current.scrollWidth - scrollContainer.current.clientWidth);
            setLeftArrowHidden(scrollContainer.current.scrollLeft <= 0);
            setRightArrowHidden(scrollContainer.current.scrollLeft + 8 >= scrollMax);
        }
        scrollContainer.current.addEventListener('scroll', scrollListener, { passive: true });
        return () => { scrollContainer.current.removeEventListener('scroll', scrollListener); }
    }, []);

    function scrollLeft() {
        let currentPosition = scrollContainer.current.scrollLeft;
        let outerWidth = scrollContainer.current.parentElement.scrollWidth;
        scrollContainer.current.scrollTo({
            left: currentPosition - outerWidth * 0.775,
            top: 0,
            behavior: 'smooth'});
    }

    function scrollRight() {
        let currentPosition = scrollContainer.current.scrollLeft;
        let outerWidth = scrollContainer.current.parentElement.scrollWidth;
        scrollContainer.current.scrollTo({
            left: currentPosition + outerWidth * 0.775,
            top: 0,
            behavior: 'smooth'});
    }

    return (
        <div className="Carousel-container header-width">
            <h3>{title}</h3>
            <img className={`Carousel-chevron left ${leftArrowHidden ? 'hidden' : '' }`} onClick={scrollLeft} src={left_chevron} />
            <img className={`Carousel-chevron right ${rightArrowHidden ? 'hidden' : '' }`} onClick={scrollRight} src={right_chevron} />
            <div ref={scrollContainer} className={"Carousel-products"}>
                {productInfo.map((product, index) => (
                    <FixedSizeProduct key={index} index={index} product={product} />
                ))}
                {props.seeAllUrl && (
                    <div className={'FixedSizeProduct-container'}>
                        <div className={'FixedSizeProduct-image-container see-more'}>
                            <div className={'inner'}>
                                <a href={props.seeAllUrl}>See All</a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Carousel;
