import './TextPage.scss'
import Header from "../components/Header";
import ReactMarkdown from "react-markdown";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useTitle} from "../components/useTitle";

function Legal(props) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    const location = useLocation()
    useEffect(() => {
        gtag('event', 'page_view', {
            page_title: document.title,
            page_location: window.location.pathname,
            page_path: window.location.search,
        });
    }, [location]);
    useTitle(props.title + ' | Vintagio');
    return (
        <>
            <Header isHero={false} />
            <div className={"Text-container header-width"}>
                <h2>{props.title}</h2>
                <br />
                <ReactMarkdown>{props.message}</ReactMarkdown>
            </div>
            <br />
        </>
    );
}

export default Legal;
