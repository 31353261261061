import './FilterList.scss';
import {useEffect, useState} from "react";
import check from '../svg/check.svg';
import check_white from '../svg/check_white.svg';
import close from "../svg/close.svg";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

function FilterList(props) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    const urlParams = new URLSearchParams(window.location.search);
    const symbolicSizes = ['XXXS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL']
    const [gender, setGender] = useState(urlParams.get('gender') || null);
    let decadeParam = urlParams.get('decades');
    const [decades, setDecades] = useState(new Set(decadeParam ? decadeParam.split(',') : []));
    let filterCategoryParam = urlParams.get('post_filter_cats');
    const [filterCategories, setFilterCategories] = useState(new Set(filterCategoryParam ? filterCategoryParam.split(',') : new Set([])));
    const [minPrice, setMinPrice] = useState(urlParams.get('price_min') || 0);
    const [maxPrice, setMaxPrice] = useState(urlParams.get('price_max') || 5000);
    const [minSymbolicSize, setMinSymbolicSize] = useState(urlParams.get('size_min_symbolic') || 0);
    const [maxSymbolicSize, setMaxSymbolicSize] = useState(urlParams.get('size_max_symbolic') || symbolicSizes.length - 1);
    const [minNumericSize, setMinNumericSize] = useState(urlParams.get('size_min_numeric') || 0);
    const [maxNumericSize, setMaxNumericSize] = useState(urlParams.get('size_max_numeric') || 22);
    const [filters, setFilters] = useState(extractFiltersFromParams());
    const [isSticky, setIsSticky] = useState(false);
    const [filtersModified, setFiltersModified] = useState(false);

    const categoryPath = props.categoryPath ? props.categoryPath : props.gender;
    const isCategoryLeaf = props.subCategories === undefined;

    useEffect(() => {
        function getStickyScrollListener(triggerThreshold) {
            return (function stickyScrollListener(e) {
                setIsSticky(window.scrollY > triggerThreshold && document.getElementsByClassName('FixedSizeProduct-container').length > 24);
            });
        }
        const stickyTriggerY =  document.getElementsByClassName('Results-filter-products-container')[0].offsetTop - document.getElementsByClassName('PopDownHeader-container')[0].scrollHeight;
        const scrollListener = getStickyScrollListener(stickyTriggerY);
        window.addEventListener('scroll', scrollListener, { passive: true });
        return () => {
            window.removeEventListener('scroll', scrollListener);
        }
    }, []);

    useEffect(() => {
        if (!isSticky) {
            let filterLists = document.getElementsByClassName('FilterList-container-inner');
            for (let i = 0; i < filterLists.length; i++) {
                filterLists[i].scrollTop = 0;
            }
        }
    },[isSticky]);

    function extractFiltersFromParams() {
        const urlParams = new URLSearchParams(window.location.search);
        let f = {};
        urlParams.forEach(function (value, key) {
            f[key] = value;
        });
        return f;
    }

    function onClose() {
        if (props.updateOnClose) {
            props.onFilterUpdate(filters);
        }
        if (props.onCloseClicked) {
            props.onCloseClicked();
        }
        setFiltersModified(false);
    }

    function easeAndSnapPriceNumber(number) {
        return Math.ceil(Math.ceil(Math.pow(number / 5000, 3.5) * 5000) / 5) * 5;
    }

    function reversePriceEasing(number) {
        return Math.pow(number / 5000, 1.0 / 3.5) * 5000;
    }

    function updatePriceRange(numbers) {
        setMinPrice(easeAndSnapPriceNumber(numbers[0]));
        setMaxPrice(easeAndSnapPriceNumber(numbers[1]));
    }

    function updateURLParams(filters) {
        const urlParams = new URLSearchParams();
        for (const [key, value] of Object.entries(filters)) {
            urlParams.set(key, value);
        }
        window.history.replaceState(null, null, '?' + urlParams.toString());
        gtag('event', 'filter', {
            'send_to': 'G-Y8ZMS72663',
            'event_callback': function() {}
        });
    }

    function updateFilters(newMin, newMax, minKey, maxKey, minDefault, maxDefault, updateFunctions) {
        if (newMin === minDefault && newMax === maxDefault && !filters[minKey] && !filters[maxKey]) { // No update
            return;
        }
        if (newMin === filters[minKey] && newMax === filters[maxKey]) { // No update
            return;
        }
        let filterCopy = {...filters}
        if (newMin === minDefault) {
            delete filterCopy[minKey];
        } else {
            filterCopy[minKey] = newMin;
        }
        if (newMax === maxDefault) {
            delete filterCopy[maxKey];
        } else {
            filterCopy[maxKey] = newMax;
        }
        if (!props.updateOnClose) {
            props.onFilterUpdate(filterCopy);
        }
        updateFunctions[0](newMin);
        updateFunctions[1](newMax);
        setFilters({...filterCopy});
        setFiltersModified(true);
        updateURLParams(filterCopy);
    }

    function onAfterPriceRangeUpdate(numbers) {
        let newMin = easeAndSnapPriceNumber(numbers[0]);
        let newMax = easeAndSnapPriceNumber(numbers[1]);
        updateFilters(newMin, newMax, 'price_min', 'price_max', 0, 5000, [setMinPrice, setMaxPrice]);
    }

    function onAfterNumericSizeUpdate(numbers) {
        updateFilters(numbers[0], numbers[1], 'size_min_numeric', 'size_max_numeric', 0, 22, [setMinNumericSize, setMaxNumericSize]);
    }

    function onAfterSymbolicSizeUpdate(numbers) {
        updateFilters(numbers[0], numbers[1], 'size_min_symbolic', 'size_max_symbolic', 0, symbolicSizes.length - 1, [setMinSymbolicSize, setMaxSymbolicSize]);
    }

    function getUpdateGenderFn(buttonValue) {
        return () => {
            let genderVal = gender === buttonValue ? null : buttonValue;

            let filterCopy = {...filters}
            if (!genderVal) {
                delete filterCopy['gender'];
            } else {
                filterCopy['gender'] = genderVal;
            }
            if (!props.updateOnClose) {
                props.onFilterUpdate(filterCopy);
            }
            setGender(genderVal);
            setFilters({...filterCopy});
            setFiltersModified(true);
            updateURLParams(filterCopy);
        }
    }

    function getUpdateDecadesFn(decade_id) {
        return (e) => {
            e.preventDefault();

            let filterCopy = {...filters};
            let decadesCopy = new Set(decades);
            if (decadesCopy.has(decade_id)) {
                decadesCopy.delete(decade_id);
            } else {
                decadesCopy.add(decade_id);
            }

            if (decadesCopy.size === 0) {
                delete filterCopy['decades'];
            } else {
                filterCopy['decades'] = Array.from(decadesCopy).join(",");
            }
            if (!props.updateOnClose) {
                props.onFilterUpdate(filterCopy);
            }

            setDecades(decadesCopy);
            setFilters({...filterCopy});
            setFiltersModified(true);
            updateURLParams(filterCopy);
        };
    }

    function getUpdateAggCategoryFn(category_id) {
        return (e) => {
            e.preventDefault();

            let filterCopy = {...filters};
            let catsCopy = new Set(filterCategories);
            if (catsCopy.has(category_id)) {
                catsCopy.delete(category_id);
            } else {
                catsCopy.add(category_id);
            }

            if (catsCopy.size === 0) {
                delete filterCopy['post_filter_cats'];
            } else {
                filterCopy['post_filter_cats'] = Array.from(catsCopy).join(",");
            }
            if (!props.updateOnClose) {
                props.onFilterUpdate(filterCopy);
            }

            setFilterCategories(catsCopy);
            setFilters({...filterCopy});
            setFiltersModified(true);
            updateURLParams(filterCopy);
        };
    }


    function getSubcategoryQueryString(fltrs) {
        if ((!props.fixedFilters || !Object.keys(props.fixedFilters).length) && (!fltrs || !Object.keys(fltrs).length)) {
            return '';
        }
        const urlParams = new URLSearchParams();
        if (props.fixedFilters && props.fixedFilters instanceof Object) {
            for (const [key, value] of Object.entries(props.fixedFilters)) {
                urlParams.set(key, value);
            }
        }

        if (fltrs && fltrs instanceof Object) {
            for (const [key, value] of Object.entries(fltrs)) {
                urlParams.set(key, value);
            }
        }
        return '?' + urlParams.toString();
    }

    return (
        <div className={`FilterList-container ${props.containerClass}`}>
            <div className={`FilterList-container-inner ${isSticky ? 'sticky' : ''}`}>
                <div className={"Header-sub-menu-top"}>
                    <h4>Filter</h4>
                    <button className={filtersModified ? 'see-results' : ''} onClick={onClose}>
                        <img src={close} className="Header-close-icon" alt="Close"/>
                        <span>See Results</span>
                    </button>
                </div>
                <div className={"FilterList-filters"}>
                    {(!props.gender || props.gender === '' || props.gender === 'unisex') && (
                        <div className={"filter"}>
                            <h5>Gender</h5>
                            <div className={"gender-button-container"}>
                                <button className={`gender-button ${gender === 'women' ? 'selected' : ''}`}
                                        onClick={getUpdateGenderFn('women')}>Women
                                </button>
                                <button className={`gender-button ${gender === 'men' ? 'selected' : ''}`}
                                        onClick={getUpdateGenderFn('men')}>Men
                                </button>
                            </div>
                        </div>
                    )}
                    {(props.subCategories && props.subCategories.length !== 0 && props.gender) && (
                        <div className={"filter"}>
                            <h5>Category</h5>
                            <ul>
                                {props.subCategories.map(function (category, index) {
                                    return (<li key={index}><a onClick={onClose}
                                                                  href={`/${categoryPath}/${category.id}${getSubcategoryQueryString(filters)}`}>{category.name}</a>
                                    </li>);
                                })}
                            </ul>
                        </div>
                    )}
                    {(!props.subCategories && !isCategoryLeaf && props.aggregations && props.aggregations.categories && props.aggregations.categories.length !== 0) && (
                        <div className={"filter"}>
                            <h5>Category</h5>
                            <ul className={"Filter-decade"}>
                                {props.aggregations.categories.map(function (category, index) {
                                    return (<li key={index}><label onClick={getUpdateAggCategoryFn(category.id)}><input
                                        value={category.id} type={"checkbox"}/><span
                                        className={`checkbox ${filterCategories.has(category.id) ? 'selected' : ''}`}><img
                                        src={props.containerClass === 'column' ? check_white : check}/></span>{category.name ? category.name : '-'}
                                    </label></li>);
                                })}
                            </ul>
                        </div>
                    )}
                    <div className={"filter"}>
                        <h5>Price</h5>
                        <div className={"filter-range"}>
                            <span>${minPrice}</span> - <span>${maxPrice}</span>
                        </div>
                        <div className={"rc-slider-container"}>
                            <Slider range={true}
                                    defaultValue={[reversePriceEasing(minPrice), reversePriceEasing(maxPrice)]} step={5}
                                    min={0} max={5000} allowCross={false} onChange={updatePriceRange}
                                    onAfterChange={onAfterPriceRangeUpdate}/>
                        </div>
                    </div>
                    {(props.decades && props.decades.length !== 0) && (
                        <div className={"filter"}>
                            <h5>Decade</h5>
                            <ul className={"Filter-decade"}>
                                {props.decades.slice(0, Math.ceil(props.decades.length / 2.0)).map(function (decade, index) {
                                    return (<li key={index}><label onClick={getUpdateDecadesFn(decade.id)}><input
                                        value={decade.id} type={"checkbox"}/><span
                                        className={`checkbox ${decades.has(decade.id) ? 'selected' : ''}`}><img
                                        src={props.containerClass === 'column' ? check_white : check}/></span>{decade.name}
                                    </label></li>);
                                })}
                            </ul>
                            <ul className={"Filter-decade"}>
                                {props.decades.slice(Math.ceil(props.decades.length / 2.0)).map(function (decade, index) {
                                    return (<li key={index}><label onClick={getUpdateDecadesFn(decade.id)}><input
                                        value={decade.id} type={"checkbox"}/><span
                                        className={`checkbox ${decades.has(decade.id) ? 'selected' : ''}`}><img
                                        src={props.containerClass === 'column' ? check_white : check}/></span>{decade.name}
                                    </label></li>);
                                })}
                            </ul>
                            <br clear="all" />
                        </div>
                    )}
                    <div className={"filter"}>
                        <h5>Size</h5>
                        <div className={"filter-range"}>
                            <span>{symbolicSizes[minSymbolicSize]}</span> - <span>{symbolicSizes[maxSymbolicSize]}</span>
                        </div>
                        <div className={"rc-slider-container"}>
                            <Slider range={true} defaultValue={[minSymbolicSize, maxSymbolicSize]} min={0}
                                    max={symbolicSizes.length - 1} allowCross={false}
                                    onChange={(numbers) => {
                                        setMinSymbolicSize(numbers[0]);
                                        setMaxSymbolicSize(numbers[1]);
                                    }}
                                    onAfterChange={onAfterSymbolicSizeUpdate}/>
                        </div>
                        <div className={"size-or-divider"}>- or -</div>
                        <div className={"filter-range"}>
                            <span>Size {minNumericSize}</span> - <span>Size {maxNumericSize}</span>
                        </div>
                        <div className={"rc-slider-container"}>
                            <Slider range={true} defaultValue={[minNumericSize, maxNumericSize]} min={0} max={22}
                                    allowCross={false}
                                    onChange={(numbers) => {
                                        setMinNumericSize(numbers[0]);
                                        setMaxNumericSize(numbers[1]);
                                    }}
                                    onAfterChange={onAfterNumericSizeUpdate}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FilterList;
