import './ResultsPage.scss';
import {useContext, useEffect, useState, useCallback, useRef} from "react";
import {MetadataContext} from "../App";
import ResultsPage from "./ResultsPage";
import {useParams} from "react-router-dom";
import {useGenderPaddedTitle} from "../components/useTitle";

function DecadeResults(props) {
    let { decade_id } = useParams();
    const {metadata} = useContext(MetadataContext);

    let pageTitle = "";
    let gender = window.location.pathname.indexOf('/women/') === 0 ? 'women' : 'men';
    let popcornLinks = [{url: '/'+gender, text: gender[0].toUpperCase() + gender.slice(1)}];
    let categories = null;
    if (!metadata || !metadata.menMenu || !metadata.menMenu.decades || metadata.menMenu.decades.length === 0) {
        pageTitle = "Loading...";
    } else {
        pageTitle = decade_id[0].toUpperCase() + decade_id.slice(1);
        metadata.menMenu.decades.forEach((decade) => {
            if (decade_id == decade.id) {
                pageTitle = decade.name;
                popcornLinks.push({url: '/'+gender+'/decade/'+decade_id, text: decade.name});
            }
        });
        let genderGroup = gender === 'women' ? metadata.womenMenu : metadata.menMenu;
        categories = genderGroup.categories
    }
    useGenderPaddedTitle(pageTitle, gender);
    return (
        <ResultsPage pageTitle={pageTitle} popcornLinks={popcornLinks} query={`https://api.vintag.io/decade?decade=${decade_id}&gender=${gender}`} gender={gender} decades={null} subCategories={categories} fixedFilters={{decades: decade_id}} />
    );
}

export default DecadeResults;

