const privacy_md = `Protecting your private information is our priority. This Statement of Privacy applies to Vintagio, and Poeko Inc and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Poeko Inc include http://vintag.io and Poeko. The Poeko application is a vintage fashion search, aggregation and curation application. By using the Poeko application, you consent to the data practices described in this statement. 
  
#### Collection of your Personal Information 
In order to better provide you with products and services offered, Poeko may collect personally identifiable information, such as your: 
  
 -\tFirst and Last Name 
 -\tMailing Address 
 -\tE-mail Address 
 -\tPhone Number 
 -\tInferred fashion preferences 
  
Poeko may also collect anonymous demographic information, which is not unique to you, such as your: 
  
 -\tAge 
 -\tGender 
  
We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future. 
  
Use of your Personal Information 
Poeko collects and uses your personal information to operate and deliver the services you have requested. 
  
Poeko may also use your personally identifiable information to inform you of other products or services available from Poeko and its affiliates. 
  
Sharing Information with Third Parties 
Poeko does not sell, rent or lease its customer lists to third parties. 
  
Poeko may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. Poeko may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Poeko, and they are required to maintain the confidentiality of your information. 
  
Poeko may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Poeko or the site; (b) protect and defend the rights or property of Poeko; and/or (c) act under exigent circumstances to protect the personal safety of users of Poeko, or the public. 
  
#### Right to Deletion 
Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will: 
•\tDelete your personal information from our records; and 
•\tDirect any service providers to delete your personal information from their records. 
  
Please note that we may not be able to comply with requests to delete your personal information if it is necessary to: 

- Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; 
- Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; 
- Debug to identify and repair errors that impair existing intended functionality; 
- Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; 
- Comply with the California Electronic Communications Privacy Act; 
- Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent; 
- Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; 
- Comply with an existing legal obligation; or 
- Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information. 
  
#### Children Under Thirteen
Poeko does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this application. 
  
#### Disconnecting your Poeko Account from Third Party Websites 
You will be able to connect your Poeko account to third party accounts. BY CONNECTING YOUR POEKO ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your account from a third party account at any time. Users may learn how to disconnect their accounts from third-party websites by visiting their "Settings" page, or by emailing hello@poeko.com 
  
#### E-mail Communications 
From time to time, Poeko may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Poeko or click on a link therein. 
  
If you would like to stop receiving marketing or promotional communications via email from Poeko, you may opt out of such communications by clicking the unsubscribe button.. 
  
#### External Data Storage Sites 
We may store your data on servers provided by third party hosting vendors with whom we have contracted. 
  
#### Changes to this Statement 
Poeko reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our application, and/or by updating any privacy information. Your continued use of the application and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy. 
  
#### Contact Information 
Poeko welcomes your questions or comments regarding this Statement of Privacy. If you believe that Poeko has not adhered to this Statement, please contact Poeko at: 
  
Poeko Inc 
10 Meadowbrook Ln. 
Lititz, Pennsylvania 17543 
  
Email Address: 
hello@poeko.com 
  _________________ 
  
Effective as of May 01, 2022 
  
`;

export default privacy_md;