import './FilterList.scss';
import './CategoryWizardOverlay.scss'
import React, {useContext, useState} from "react";
import close from "../svg/close.svg";
import 'rc-slider/assets/index.css';
import {MetadataContext} from "../App";
import {Link} from "react-router-dom";

function CategoryWizardOverlay(props) {
    const {metadata} = useContext(MetadataContext);
    const [gender, setGender] = useState(null);
    const [topCategory, setTopCategory] = useState(null)

    function onClose() {
        props.onCloseClicked();
        setTimeout(() => {
            setGender(null);
            setTopCategory(null);
        }, 450);
    }

    return (
        <div className={`FilterList-container CategoryWizardOverlay-container ${props.containerClass}`}>
            <div className={"Header-sub-menu-top"}>
                <h4>Browse by Category</h4>
                <button onClick={onClose}>
                    <img src={close} className="Header-close-icon" alt="Close"/>
                </button>
            </div>
            <div className={"FilterList-filters"}>
                {!gender && (
                    <div className={"CategoryWizardOverlay-gender-select"}>
                        <button onClick={() => {
                            setGender("women")
                        }} className={"genderButton women"}><span className={"overlay"}>Women</span>
                        </button>
                        <button onClick={() => {
                            setGender("men")
                        }} className={"genderButton men"}><span className={"overlay"}>Men</span>
                        </button>
                    </div>
                )}
                {(gender && !topCategory) && (
                    <div className={"CategoryWizardOverlay-category-select"}>
                        <ul>
                            <li key={"base"}><a className={"shopAll"} href={`/${gender}`}>Shop All {gender === 'women' ? "Women's" : "Men's"}</a></li>
                            {(gender === 'women' ? metadata.womenMenu : metadata.menMenu).categories.map(function (category) {
                                return (<li key={category.id}>
                                    <button onClick={() => {
                                        setTopCategory(category);
                                    }}
                                    >{category.name}</button>
                                </li>);
                            })}
                        </ul>
                    </div>
                )}
                {(gender && topCategory) && (
                    <div className={"CategoryWizardOverlay-category-select"}>
                        <ul>
                            <li key={"base"}><a className={"shopAll"} href={`/${gender}/${topCategory.id}`}>Shop All {topCategory.name}</a></li>
                            {topCategory.children.map(function (category) {
                                return (<li key={category.id}>
                                    <a href={`/${gender}/${category.id}`}>{category.name}</a>
                                </li>);
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CategoryWizardOverlay;
