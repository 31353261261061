import './SearchBox.scss';
import search from '../svg/search.svg';
import {useContext, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {MetadataContext} from "../App";

function SearchBox(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get('q');
    const [isSearchActive, setSearchActive] = useState(false);
    const [dynamicQuery, setDynamicQuery] = useState(searchParams.get('q'));
    const [width, setWidth] = useState(window.innerWidth);
    let placeholder = 'Search Products'
    if (width > 780 && width < 1264) {
        placeholder = 'Search decades, styles, categories, brands, etc...'
    }
    return (
        <div onMouseEnter={props.onMouseEnter} className="SearchBox-container" >
            <div className={`SearchBox-border ${(isSearchActive || (dynamicQuery)) ? 'activated' : ''}`}>
                <form className="SearchBox-form" method={"GET"} action={"/search"} autoComplete={"off"}>
                    <input name={"q"} type="search" id={props.elementId} value={dynamicQuery ? dynamicQuery : ''} onFocus={() => {setSearchActive(true)}} onBlur={() => {setSearchActive(false)}} onChange={(e) => setDynamicQuery(e.target.value)} placeholder={placeholder} />
                    <button type="submit">
                        <img src={search} alt="Search" />
                    </button>
                </form>
            </div>
        </div>
    );
}

export default SearchBox;
