import './ResultsPage.scss';
import {useContext} from "react";
import {MetadataContext} from "../App";
import ResultsPage from "./ResultsPage";
import {useGenderPaddedTitle} from "../components/useTitle";
import {genderFromURL} from "../components/utils";


function StaffResults(props) {
    const {metadata} = useContext(MetadataContext);

    let pageTitle = "Staff Picks";
    let gender = genderFromURL();
    useGenderPaddedTitle(pageTitle, gender);
    let query = "https://api.vintag.io/staff_picks?"
    let popcornLinks = [
        {url: '/'+gender+'/staff', text: 'Staff Picks'}
    ];
    if (gender !== 'unisex') {
        query += `gender=${gender}`
        popcornLinks.unshift({url: '/'+gender, text: gender[0].toUpperCase() + gender.slice(1)});
    }

    return (
        <ResultsPage pageTitle={pageTitle} popcornLinks={popcornLinks} query={query} gender={gender} decades={null} />
    );
}

export default StaffResults;

