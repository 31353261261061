import './TextPage.scss'
import Header from "../components/Header";
import Carousel from "../components/Carousel";
import SearchSuggestions from "../components/SearchSuggestions";

function Error(props) {
    return (
        <>
            <Header isHero={false} />
            <div className={"Error-container header-width"}>
                <h2>{props.title}</h2>
                <br />
                <p>{props.message}</p>
            </div>
            <br />
            <SearchSuggestions />
            <Carousel title={"Recently Added"} baseUrl={"https://api.vintag.io/recent?sort=rand&nolog=1"} />
            <br />
        </>
    );
}

export default Error;
