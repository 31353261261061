import './ResultsTitleBar.scss';
import FilterButton from "./FilterButton";

function ResultsTitleBar(props) {
    return (
        <>
            <div className={"ResultsTitleBar-Bar"}>
                <div className={`ResultsTitleBar-container header-width ${props.resultCount ? '' : 'no-results-count'}`}>
                    <h1>{props.pageName}</h1>
                    {props.resultCount && <div className={"ResultsTitleBar-result-count"}><span className={"divider"}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>{props.resultCount} results</div>}
                    {props.onFilterClick && <FilterButton onClick={props.onFilterClick} />}
                </div>
            </div>
            {props.popcornLinks &&
                <div className={"PopcornLinks header-width"}>
                    <ul>
                        {props.popcornLinks.map(function (link, index) {
                            return (<li key={index}>
                                {/*{link}*/}
                                <a href={link.url}>{link.text}</a>
                                {index + 1 !== props.popcornLinks.length && (
                                    <svg width="5" height="7" viewBox="0 0 5 7" xmlns="http://www.w3.org/2000/svg"
                                         className="Breadcrumbs-module__separator___0poNC">
                                        <path
                                            d="M0.827703 1.4472e-07L-1.42791e-07 0.816667L2.71959 3.5L-1.08113e-06 6.18333L0.827701 7L4.375 3.5L0.827703 1.4472e-07Z"></path>
                                    </svg>
                                )}
                            </li>);
                        })}
                    </ul>
                </div>}
        </>
    );
}

export default ResultsTitleBar;
