import { useEffect } from "react";

export function useGenderPaddedTitle(title, gender) {
    useEffect(() => {
        const prevTitle = document.title
        let gender_prefix = ''
        if (gender && gender.length > 0 && gender !== 'unisex') {
           gender_prefix = (gender === 'women' ? "Women's " : "Men's ");
        }
        document.title =  gender_prefix + title + ' | Vintagio - All the Vintage Clothing on the Web'
        return () => {
            document.title = prevTitle
        }
    })
}

export function useTitle(title) {
    useEffect(() => {
        const prevTitle = document.title
        document.title = title
        return () => {
            document.title = prevTitle
        }
    })
}