import './TextPage.scss'
import './Brands.scss'
import Header from "../components/Header";
import {useLocation} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {useTitle} from "../components/useTitle";
import ResultsTitleBar from "../components/ResultsTitleBar";
import {MetadataContext} from "../App";

function Brands() {
    const abcUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ#";
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    const location = useLocation()
    const {metadata} = useContext(MetadataContext);

    let gender = window.location.pathname.indexOf('/women/') === 0 ? 'women' : 'men';

    let letterToBrands = {};
    if (metadata && metadata.brands) {
        for (let i = 0; i < metadata.brands['all'].length; i++) {
            let brand = metadata.brands['all'][i];
            let letter = brand[0].toUpperCase();
            if (!abcUpper.includes(letter)) {
                letter = '#'
            }
            if (!(letter in letterToBrands)) {
                letterToBrands[letter] = [];
            }
            letterToBrands[letter].push(brand);
        }
    }

    useEffect(() => {
        gtag('event', 'page_view', {
            page_title: document.title,
            page_location: window.location.pathname,
            page_path: window.location.search,
        });
    }, [location]);
    useTitle('Brands - Vintage Clothing | Vintagio');
    return (
        <>
            <Header isHero={false} />
            <ResultsTitleBar pageName={'Brands'} />
            <div className={'Brands-container header-width'} >
                <div className={'Brands-a-z'}>
                    <ul>
                        {abcUpper.split('').map(function (char, index) {
                            return (<li key={index}><a href={`#brands-anchor-${char}`}>{char}</a></li>)
                        })}
                    </ul>
                </div>
                <hr />
                <h3>Top Brands</h3>
                <ul className={"brand-list"}>
                    {(metadata && metadata.brands) && (
                        metadata.brands['top'].map(function (brand, index) {
                                return (<li key={index}><a href={`/${gender}/brand/${brand}`}>{brand}</a>
                                </li>);
                            })
                    )}
                </ul>
                <hr />
                <h3>All Brands</h3>
                    {(metadata && metadata.brands && letterToBrands['A']) && (
                        (abcUpper).split('').map(function (char, index) {
                            return (<div key={index} id={`brands-anchor-${char}`} className={"Brand-letter"}>
                                <h4>{char.toUpperCase()}</h4>
                                <ul className={"brand-list"}>
                                {letterToBrands[char].map(function (brand, index) {
                                    return (<li key={index}><a href={`/${gender}/brand/${brand}`}>{brand}</a>
                                    </li>);
                                })}
                                </ul>
                            </div>);
                        })
                    )}
            </div>
        </>
    );
}

export default Brands;
