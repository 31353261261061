import './ResultsPage.scss';
import {useContext, useEffect, useState, useCallback, useRef} from "react";
import {MetadataContext} from "../App";
import ResultsPage from "./ResultsPage";
import {useTitle} from "../components/useTitle";

function GenderResults(props) {
    const {metadata} = useContext(MetadataContext);

    let gender = window.location.pathname.indexOf('/women') === 0 ? 'women' : 'men';
    let pageTitle = gender === 'women' ? "Women's Vintage Clothing" : "Men's Vintage Clothing";
    let categories = null;
    if (metadata) {
        let genderGroup = gender === 'women' ? metadata.womenMenu : metadata.menMenu;
        categories = genderGroup.categories
    }
    useTitle(pageTitle + '| Vintagio')
    return (
        <ResultsPage pageTitle={pageTitle} query={`https://api.vintag.io/gender?&gender=${gender}`} gender={gender} decades={metadata.menMenu.decades} subCategories={categories} />
    );
}

export default GenderResults;

