import './Footer.scss';

function Footer() {
    return (
        <footer className={"Footer-bg-bar"}>
            <div className={"Footer-container header-width"}>
                <div className={"Footer-copyright"}>© {new Date().getFullYear()} Poeko Inc. All Rights Reserved</div>
                <div className={"Footer-privacy-terms"}><a href={"/privacy"}>Privacy</a> | <a href={"/terms"}>Terms</a></div>
            </div>
        </footer>
    );
}

export default Footer;
