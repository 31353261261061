import './FixedSizeProduct.scss';
import {useState, useEffect} from "react";
import {useCookies} from "react-cookie";
import ky from "ky";

function FixedSizeProduct(props) {
    const SIZE_NAMES = ['XXXS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    const [cookies, setCookie] = useCookies(['userId']);
    const [containerClass, setContainerClass] = useState("empty");
    const [imgURL, setImageURL] = useState("");
    const product = props.product;
    const isThrilling = product && product.url && product.url.toLowerCase().includes('shopthrilling.com') && product.image_url && product.image_url.toLowerCase().includes('.png');
    useEffect(() => {
        if (product.image_url && product.image_url !== '') {
            const imageToLoad = new Image();
            imageToLoad.src = product.image_url;
            imageToLoad.onload = () => {
                setImageURL(product.image_url);
                setContainerClass("populated");
            }
            imageToLoad.onerror = () => {
                setContainerClass("removed");
            }
        } else if (Object.keys(product).length === 0) {
            setImageURL('')
            setContainerClass('empty')
        }
    }, [product]);

    function getSizeStr(product) {
        if (product.size_min_symbolic && product.size_max_symbolic) {
            if (product.size_min_symbolic === product.size_max_symbolic) {
                return SIZE_NAMES[product.size_min_symbolic];
            }
            if (product.size_min_symbolic+1 === product.size_max_symbolic) {
                return SIZE_NAMES[product.size_min_symbolic] + "/" + SIZE_NAMES[product.size_max_symbolic];
            }
            return SIZE_NAMES[product.size_min_symbolic] + "-" + SIZE_NAMES[product.size_max_symbolic];
        }
        if (product.size_min_numeric && product.size_max_numeric) {
            if (product.size_min_numeric === product.size_max_numeric) {
                return product.size_min_numeric;
            }
            return product.size_min_numeric + "-" + product.size_max_numeric;
        }
        return null;
    }

    function logClick(product) {
        ky.get('https://api.vintag.io/log?event=product_click&id='+product.id, {
            headers: {
                'x-vintagio-session-id': cookies.userId
            }});
        gtag('event', 'productClick', {
            'send_to': 'G-Y8ZMS72663',
            'event_callback': function() {}
        });
    }

    function getSeoSlug(product) {
        if (!product.formatted_name || product.formatted_name.length === 0) {
            return "info"
        }
        return product.formatted_name.toLowerCase().replace(/[^a-z0-9\s]/gi, '').trim().replaceAll(/\s+/g, '-');

    }

    return (
        <div key={props.index} onClick={() => {logClick(product);}} className={`FixedSizeProduct-container ${containerClass}`}>
            <a href={"/product/" + product.id + "/" + getSeoSlug(product)} target={"_blank"}>
                <div className={`FixedSizeProduct-image-container ${isThrilling ? 'thrilling-png' : ''}`}>
                    <div className={"aspect-spacer"} />
                    <div style={{backgroundImage: `url(${imgURL})`}} className={`FixedSizeProduct-image-blur-bg`}  />
                    <img src={imgURL} />
                    <div className={"FixedSizeProduct-gradient-overlay"} />
                </div>
                <h4>{product.formatted_name && product.formatted_name.length !== 0 ? product.formatted_name : "\u00a0"}</h4>
                <div className={"FixedSizeProduct-price"}>{product.formatted_price}</div>
                {getSizeStr(product) && (<div className={"FixedSizeProduct-size"}>{getSizeStr(product)}</div>)}
            </a>
        </div>
    );
}

export default FixedSizeProduct;
