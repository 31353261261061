import './ResultsPage.scss';
import {useParams} from "react-router-dom";
import {useContext} from "react";
import {MetadataContext} from "../App";
import ResultsPage from "./ResultsPage";
import {useGenderPaddedTitle} from "../components/useTitle";
import {getParentCategory} from "../components/utils";

function CategoryResults(props) {
    let { category_id } = useParams();
    const {metadata} = useContext(MetadataContext);

    let pageTitle = "";
    let subCategories = []
    let gender = window.location.pathname.indexOf('/women/') === 0 ? 'women' : 'men';
    let popcornLinks = [{url: '/'+gender, text: gender[0].toUpperCase() + gender.slice(1)}];
    if (!metadata || !metadata.taxonomy) {
        pageTitle = "Loading...";
    } else {
        let genderGroup = gender === 'women' ? metadata.taxonomy.women : metadata.taxonomy.men;

        if (genderGroup) {
            if (!genderGroup[category_id]) {
                window.location.href = '/404'
            }
            let category = genderGroup[category_id];
            pageTitle =  category.name;
            subCategories = category.children;

            let parentCat = getParentCategory(genderGroup, category_id);
            if (parentCat) {
                popcornLinks.push({url: '/'+gender+'/'+parentCat.id, text: parentCat.name})
            }
            popcornLinks.push({url: '/'+gender+'/'+category_id, text: category.name})
        }
    }
    useGenderPaddedTitle(pageTitle, gender);

    return (
        <ResultsPage pageTitle={pageTitle} popcornLinks={popcornLinks} query={`https://api.vintag.io/category?category_id=${category_id}&gender=${gender}`} gender={gender} decades={metadata.menMenu.decades} subCategories={subCategories} />
    );
}

export default CategoryResults;

