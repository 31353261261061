import './App.css';
import Home from './pages/Home'
import CategoryResults from "./pages/CategoryResults";
import Footer from "./components/Footer";
import {useState, createContext, useEffect, useMemo} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";
import ky from "ky";
import DecadeResults from "./pages/DecadeResults";
import GenderResults from "./pages/GenderResults";
import SearchResults from "./pages/SearchResults";
import BrandResults from "./pages/BrandResults";
import RecentResults from "./pages/RecentResults";
import StaffResults from "./pages/StaffResults";
import Brands from "./pages/Brands";
import Error from "./pages/Error";
import Legal from "./pages/Legal";
import privacy_md from "./text_content/privacy";
import terms_md from "./text_content/terms";
import {useCookies} from "react-cookie";
import ProductDetails from "./pages/ProductDetails";
var md5 = require('md5');

export const MetadataContext = createContext({});

function App() {
    const [cookies, setCookie] = useCookies(['userId']);
    const [metadata, setMetadata] = useState({taxonomy: {}, womenMenu: {categories: [], decades: [], brands: []}, menMenu: {categories: [], decades: [], brands: {}}});
    const value = useMemo(() => ({metadata, setMetadata}), [metadata]);

    function generateUserId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }

    useEffect(() => {
        async function loadMetadata() {
            const now = new Date();
            const expires = new Date(+now + 3*24*3600*1000); // 3 days
            let response = await ky.get('https://api.vintag.io/metadata').json();
            const wMenu = {categories: response.navigation.categories.women, decades: response.navigation.decades, brands: response.navigation.brands.women, expires: expires};
            const mMenu = {categories: response.navigation.categories.men, decades: response.navigation.decades, brands: response.navigation.brands.men, expires: expires}
            const uMenu = {categories: response.navigation.categories.unisex, decades: response.navigation.decades, brands: [], expires: expires}
            localStorage.setItem("Header_mensMenu", JSON.stringify(mMenu));
            localStorage.setItem("Header_womensMenu", JSON.stringify(wMenu));
            localStorage.setItem("Header_unisexMenu", JSON.stringify(uMenu));
            localStorage.setItem("Taxonomy", JSON.stringify(response.taxonomy));
            localStorage.setItem("Brands", JSON.stringify(response.navigation.brands));
            setMetadata({taxonomy: response.taxonomy, menMenu: mMenu, womenMenu: wMenu, unisexMenu: uMenu, brands: response.navigation.brands})
        }
        const now = new Date();
        const storedMenMenu = JSON.parse(localStorage.getItem("Header_mensMenu"));
        const storedWomenMenu = JSON.parse(localStorage.getItem("Header_womensMenu"));
        const storedUnisexMenu = JSON.parse(localStorage.getItem("Header_unisexMenu"));
        const storedTaxonomy = JSON.parse(localStorage.getItem("Taxonomy"));
        const storedBrands = JSON.parse(localStorage.getItem("Brands"));
        if (!storedBrands || !storedMenMenu || !storedWomenMenu || !storedUnisexMenu || !storedMenMenu.expires || new Date(storedMenMenu.expires) < now || !storedWomenMenu.expires || new Date(storedWomenMenu.expires) < now) {
            loadMetadata();
        } else {
            setMetadata({taxonomy: storedTaxonomy, menMenu: storedMenMenu, womenMenu: storedWomenMenu, unisexMenu: storedUnisexMenu, brands: storedBrands});
        }
        if (!cookies.userId) {
            const userId = generateUserId();
            setCookie('userId', userId, { path: '/' });
            const screen = '' + window.screen.width + 'x' + window.screen.height
            ky.get('https://api.vintag.io/log?event=session_start&log_ip=true&screen_size='+screen+'&agent='+md5(window.navigator.userAgent)+'&referrer='+encodeURIComponent(document.referrer), {
                headers: {
                    'x-vintagio-session-id': userId
                }});
        }
    }, []);
    return (
        <Router>
            <MetadataContext.Provider value={value}>
              <div className="App">
                  <Routes>
                      <Route path="/women/brand/:brand_query" element={<BrandResults />} />
                      <Route path="/men/brand/:brand_query" element={<BrandResults />} />
                      <Route path="/women/brands" element={<Brands />} />
                      <Route path="/men/brands" element={<Brands />} />
                      <Route path="/women/decade/:decade_id" element={<DecadeResults />} />
                      <Route path="/men/decade/:decade_id" element={<DecadeResults />} />
                      <Route path="/women/recent/:category_id" element={<RecentResults />} />
                      <Route path="/men/recent/:category_id" element={<RecentResults />} />
                      <Route path="/unisex/recent/:category_id" element={<RecentResults />} />
                      <Route path="/women/recent" element={<RecentResults />} />
                      <Route path="/men/recent" element={<RecentResults />} />
                      <Route path="/unisex/recent" element={<RecentResults />} />
                      <Route path="/women/staff" element={<StaffResults />} />
                      <Route path="/men/staff" element={<StaffResults />} />
                      <Route path="/unisex/staff" element={<StaffResults />} />
                      <Route path="/women/:category_id" element={<CategoryResults />} />
                      <Route path="/men/:category_id" element={<CategoryResults />} />
                      <Route path="/women" element={<GenderResults />} />
                      <Route path="/men" element={<GenderResults />} />
                      <Route path="/search" element={<SearchResults />} />
                      <Route path="/product/:product_id/:seo_name" element={<ProductDetails />} />
                      <Route path="/privacy" element={<Legal title={"Privacy Policy"} message={privacy_md} />} />
                      <Route path="/terms" element={<Legal title={"Terms of Use"} message={terms_md} />} />
                      <Route path="/" element={<Home/>} />
                      <Route path="/404" element={<Error title={"404 - Page Not Found"} message={"Sorry we couldn't find what you were looking for."}/>} />
                      <Route path="/*" element={<Error title={"404 - Page Not Found"} message={"Sorry we couldn't find what you were looking for."}/>} />
                  </Routes>
                  <Footer />
              </div>
          </MetadataContext.Provider>
      </Router>
  );
}

export default App;
