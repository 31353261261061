import './SearchOverlay.scss';
import React, {useState} from "react";
import close from "../svg/close.svg";
import 'rc-slider/assets/index.css';
import SearchBox from "./SearchBox";

function SearchOverlay(props) {

    function onClose() {
        props.onCloseClicked();
    }

    return (
        <div className={`SearchOverlay-container ${props.containerClass}`}>
            <div className={"Header-sub-menu-top"}>
                <SearchBox elementId={`js-search-overlay-search-box-${props.searchBoxUID}`} />
                <button onClick={onClose}>
                    <img src={close} className="Header-close-icon" alt="Close"/>
                </button>
            </div>
            <div className={"SearchOverlay-suggestions"}>
                <h5>Search Suggestions:</h5>
                <ul>
                    <li><a href={"/search?q=cottagecore+dress"}>Cottagecore Dress</a></li>
                    <li><a href={"/search?q=60s+mod"}>60s Mod</a></li>
                    <li><a href={"/search?q=gunne+sax"}>Gunne Sax</a></li>
                    <li><a href={"/search?q=streetwear"}>Streetwear</a></li>
                    <li><a href={"/search?q=gucci+sunglasses"}>Gucci Sunglasses</a></li>
                </ul>
            </div>
        </div>
    );
}

export default SearchOverlay;
