import './ResultsPage.scss';
import {useContext} from "react";
import {MetadataContext} from "../App";
import ResultsPage from "./ResultsPage";
import {useSearchParams} from "react-router-dom";
import {useTitle} from "../components/useTitle";

function SearchResults(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const {metadata} = useContext(MetadataContext);

    let query = searchParams.get('q');
    let pageTitle = `Results for: "${query}"`
    useTitle(query + " | Vintagio - All the Vintage Clothing on the Web")
    return (
        <ResultsPage pageTitle={pageTitle} query={`https://api.vintag.io/search?&q=${query}`} gender={null} decades={metadata.menMenu.decades} subCategories={null} />
    );
}

export default SearchResults;

