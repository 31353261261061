import logo from '../svg/logo.svg';
import icon from '../svg/icon.svg';
import search from '../svg/search.svg';
import close from '../svg/close.svg'
import arrow from '../svg/menu_hover_arrow.svg'
import './Header.scss';
import React, {useState, useLayoutEffect, useEffect, useContext} from 'react';
import SearchBox from "./SearchBox"
import {MetadataContext} from "../App";
import SearchOverlay from "./SearchOverlay";

function Header(props) {
    let is_hero = props.isHero;
    let is_popdown = props.isPopDown;
    const [isTouch, setTouch] = useState(window.innerWidth < 850);
    const [isWomenHover, setWomenHover] = useState(false);
    const [isWomenClicked, setWomenClicked] = useState(false);
    const [isMenHover, setMenHover] = useState(false);
    const [isMenClicked, setMenClicked] = useState(false);
    const [isWomenHoverDisabled, setWomenHoverDisabled] = useState(!isTouch && window.location.pathname === '/women/all');
    const [isMenHoverDisabled, setMenHoverDisabled] = useState(!isTouch && window.location.pathname === '/men/all');
    const [isSearchOverlayVisible, setSearchOverlayVisible] = useState(false);
    const [showPopDownMenu, setShowPopDownMenu] = useState(false);
    const {metadata} = useContext(MetadataContext);
    const searchBoxUID = '' + Math.floor(Math.random()*100000);

    function closeMenu() {
        setWomenClicked(false);
        setWomenHover(false);
        setMenHover(false);
        setMenClicked(false);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setWomenHoverDisabled(false);
            setMenHoverDisabled(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (isSearchOverlayVisible) {
            document.getElementById("js-search-overlay-search-box-"+searchBoxUID).focus();
        }
    }, [isSearchOverlayVisible]);

    useLayoutEffect(() => {
        function handleResize() {
            setTouch(window.innerWidth < 850);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleScroll = () => {
        setShowPopDownMenu(window.scrollY > 10.0);
    };
    useEffect(() => {
        if (props.isPopDown) {
            window.addEventListener('scroll', handleScroll, {passive: true});
        }

        return () => {
            if (props.isPopDown) {
                window.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    function shouldShowSubMenu() {
        return (isTouch && (isWomenClicked || isMenClicked)) || (!isTouch && (!isWomenHoverDisabled || !isMenHoverDisabled) && (isWomenHover || isMenHover));
    }

    return (
        <>
            <SearchOverlay containerClass={isSearchOverlayVisible ? 'active' : ''} searchBoxUID={searchBoxUID} onCloseClicked={() => {
                setSearchOverlayVisible(false);
            }}/>
            {!is_popdown && (
                <div className={`Header-spacer ${is_hero ? "hero" : ""}`}/>
            )}
            <div
                className={`Header-container ${is_hero ? "hero" : ""} ${(isTouch && (isWomenClicked || isMenClicked)) || (!isTouch && (isWomenHover || isMenHover)) ? "active" : ""}`}
                onMouseLeave={() => {
                    setMenHover(false);
                    setWomenHover(false);
                }}>
                <header className={`Header-header ${props.isPopDown && !showPopDownMenu ? 'hidden-top-pop-down' : ''}`}>
                    <a href="/"><img src={props.isPopDown ? icon : logo} className="Header-logo" alt="Vintagio"/></a>
                    <SearchBox/>
                    {!isTouch && (<div onMouseEnter={closeMenu} className={"Header-menu-killer"}/>)}
                    <nav>
                        <img src={arrow}
                             className={`Header-arrow ${shouldShowSubMenu() ? 'active' : ''} ${props.isPopDown ? 'pop-down' : ''}
                             ${(!isWomenHoverDisabled && ((isTouch && isWomenClicked) || (!isTouch && isWomenHover))) ? 'women' : 'men'}`}
                             alt="Arrow"/>
                        <ul>
                            {!is_hero && (
                                <li className="Header-icon"><a onClick={(e) => {
                                    e.preventDefault();
                                    setSearchOverlayVisible(true);
                                }} href="#"><img src={search} className="Header-search"
                                                 alt="Search"/></a></li>
                            )}
                            <li className="Header-link"><a onMouseEnter={() => {
                                setWomenHover(true);
                                setMenHover(false);
                            }}
                                                           onMouseLeave={() => {
                                                               setTimeout(() => {
                                                                   setWomenHoverDisabled(false)
                                                               }, 1000);
                                                           }}
                                                           onClick={(e) => {
                                                               if (isTouch) {
                                                                   e.preventDefault();
                                                               } else {
                                                                   closeMenu();
                                                               }
                                                               setWomenClicked(true);
                                                               setMenClicked(false);
                                                           }}
                                                           href='/women'>Women</a>
                            </li>
                            <li className="Header-link"><a onMouseEnter={() => {
                                setMenHover(true);
                                setWomenHover(false);
                            }}
                                                           onMouseLeave={() => {
                                                               setTimeout(() => {
                                                                   setMenHoverDisabled(false)
                                                               }, 1000);
                                                           }}
                                                           onClick={(e) => {
                                                               if (isTouch) {
                                                                   e.preventDefault();
                                                               } else {
                                                                   closeMenu();
                                                               }
                                                               setMenClicked(true);
                                                               setWomenClicked(false);
                                                           }}
                                                           href='/men'>Men</a>
                            </li>
                        </ul>
                    </nav>
                </header>
                <div
                    className={`Header-sub-menu-container ${shouldShowSubMenu() ? 'active' : ''}`}>
                    {(!isWomenHoverDisabled && ((isTouch && isWomenClicked) || (!isTouch && isWomenHover))) && (
                        <>
                            <div className={"Header-sub-menu-top"}>
                                <h4>Women</h4>
                                <button onClick={closeMenu}>
                                    <img src={close} className="Header-close-icon" alt="Close"/>
                                </button>
                            </div>
                            <div className={"Header-sub-menu-links"}>
                                <div className={"Header-sub-menu-column"}>
                                    <ul className={"larger"}>
                                        <li><a href={"/women"}>Shop All</a></li>
                                        <li><a href={"/women/recent"}>Recently Added</a></li>
                                        <li><a href={"/women/staff"}>Staff Picks</a></li>
                                    </ul>
                                </div>
                                <div className={"Header-sub-menu-column"}>
                                    <h4>Category</h4>
                                    <ul>
                                        {metadata.womenMenu.categories.map(function (category) {
                                            return (<li key={category.id}><a 
                                                                                href={`/women/${category.id}`}>{category.name}</a>
                                            </li>);
                                        })}
                                    </ul>
                                </div>
                                <div className={"Header-sub-menu-column"}>
                                    <h4>Decade</h4>
                                    <ul className={"small"}>
                                        {metadata.womenMenu.decades.slice(0, Math.ceil(metadata.womenMenu.decades.length / (isTouch ? 2.0 : 1.0))).map(function (decade) {
                                            return (<li key={decade.id}><a 
                                                                              href={`/women/decade/${decade.id}`}>{decade.name}</a>
                                            </li>);
                                        })}
                                    </ul>
                                    <ul className={"small"}>
                                        {metadata.womenMenu.decades.slice(Math.ceil(metadata.womenMenu.decades.length / (isTouch ? 2.0 : 1.0)), metadata.womenMenu.decades.length).map(function (decade) {
                                            return (<li key={decade.id}><a 
                                                                              href={`/women/decade/${decade.id}`}>{decade.name}</a>
                                            </li>);
                                        })}
                                    </ul>
                                    <div className={"small-clear"}/>
                                </div>
                                <div className={"Header-sub-menu-column"}>
                                    <h4>Brand</h4>
                                    <ul>
                                        {metadata.womenMenu.brands.map(function (brand) {
                                            return (<li key={brand}><a 
                                                                          href={`/women/brand/${brand}`}>{brand}</a>
                                            </li>);
                                        })}
                                        <li className={"browser-all-brands"}><a href={"/women/brands"} >Browse all...</a></li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                    {(!isMenHoverDisabled && ((isTouch && isMenClicked) || (!isTouch && isMenHover))) && (
                        <>
                            <div className={"Header-sub-menu-top"}>
                                <h4>Men</h4>
                                <button onClick={closeMenu}>
                                    <img src={close} className="Header-close-icon" alt="Close"/>
                                </button>
                            </div>
                            <div className={"Header-sub-menu-links"}>
                                <div className={"Header-sub-menu-column"}>
                                    <ul className={"larger"}>
                                        <li><a href={"/men"}>Shop All</a></li>
                                        <li><a href={"/men/recent"}>Recently Added</a></li>
                                        <li><a href={"/men/staff"}>Staff Picks</a></li>
                                    </ul>
                                </div>
                                <div className={"Header-sub-menu-column"}>
                                    <h4>Category</h4>
                                    <ul>
                                        {metadata.menMenu.categories.map(function (category) {
                                            return (<li key={category.id}><a 
                                                                                href={`/men/${category.id}`}>{category.name}</a>
                                            </li>);
                                        })}
                                    </ul>
                                </div>
                                <div className={"Header-sub-menu-column"}>
                                    <h4>Decade</h4>
                                    <ul className={"small"}>
                                        {metadata.menMenu.decades.slice(0, Math.ceil(metadata.menMenu.decades.length / (isTouch ? 2.0 : 1.0))).map(function (decade) {
                                            return (<li key={decade.id}><a 
                                                                              href={`/men/decade/${decade.id}`}>{decade.name}</a>
                                            </li>);
                                        })}
                                    </ul>
                                    <ul className={"small"}>
                                        {metadata.menMenu.decades.slice(Math.ceil(metadata.menMenu.decades.length / (isTouch ? 2.0 : 1.0)), metadata.menMenu.decades.length).map(function (decade) {
                                            return (<li key={decade.id}><a 
                                                                              href={`/men/decade/${decade.id}`}>{decade.name}</a>
                                            </li>);
                                        })}
                                    </ul>
                                    <div className={"small-clear"}/>
                                </div>
                                <div className={"Header-sub-menu-column"}>
                                    <h4>Brand</h4>
                                    <ul>
                                        {metadata.menMenu.brands.map(function (brand) {
                                            return (<li key={brand}><a 
                                                                          href={`/men/brand/${brand}`}>{brand}</a>
                                            </li>);
                                        })}
                                        <li className={"browser-all-brands"}><a href={"/men/brands"} >Browse all...</a></li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Header;
