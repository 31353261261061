import './ResultsPage.scss';
import {useContext} from "react";
import {MetadataContext} from "../App";
import ResultsPage from "./ResultsPage";
import {useParams, useSearchParams} from "react-router-dom";
import {useGenderPaddedTitle} from "../components/useTitle";

function BrandResults(props) {

    let { brand_query } = useParams();
    const {metadata} = useContext(MetadataContext);

    let pageTitle = brand_query;
    let gender = window.location.pathname.indexOf('/women/') === 0 ? 'women' : 'men';
    let popcornLinks = [
        {url: '/'+gender, text: gender[0].toUpperCase() + gender.slice(1)},
        {url: '/'+gender+'/brand/'+brand_query, text: brand_query}
    ];
    useGenderPaddedTitle(pageTitle, gender);


    return (
        <ResultsPage pageTitle={pageTitle} popcornLinks={popcornLinks} query={`https://api.vintag.io/search?&q=${brand_query}&gender=${gender}`} gender={gender} decades={metadata.menMenu.decades} subCategories={null} />
    );
}

export default BrandResults;

