import './Hero.scss';
import SearchBox from "./SearchBox";
import CategoryWizardOverlay from "./CategoryWizardOverlay";
import {useState} from "react";

function Hero() {
    const [isCategoryWizsardVisible, setCategoryWizardVisible] = useState(false);
    const allImages = ['nj-girl.jpg', 'lens-flare-forest.jpg', 'summer-car-girl.jpg','gate-girl.jpg'];
    let img_name = allImages[Math.floor(Math.random()*allImages.length)]
    let bg_img_url = '/imgs/heros/' + img_name;
    return (
        <>
            <CategoryWizardOverlay containerClass={isCategoryWizsardVisible ? "active" : 'inactive'} onCloseClicked={() => {setCategoryWizardVisible(false)}} />
            <div className="Hero-container" style={{backgroundImage: `url(${bg_img_url})`}}>
                <div className={"Hero-overlay"} />
                <div className={"Hero-spacer"} />
                <h1>All the Vintage&nbsp;Clothing on the Internet, Curated Just&nbsp;for&nbsp;You.</h1>
                <h2>Search and browse vintage fashion from all the top sites.
                    Vintagio learns your preferences and tailors its results to fit your style.</h2>
                <SearchBox />
                <button onClick={() => {
                    setCategoryWizardVisible(true)}} className="Hero-browser-button">Browse by Category</button>
            </div>
        </>
    );
}

export default Hero;