import { useState, useEffect, useCallback } from "react";
import ky from "ky";
import {useCookies} from "react-cookie";

function useProductFetch(query, page, filters) {
    const [cookies] = useCookies(['userId']);
    const [lastQueryId, setLastQueryId] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [resultCount, setResultCount] = useState('-');
    const [totalPages, setTotalPages] = useState(1);
    const [products, setProducts] = useState(Array(32).fill({}));
    const [aggregations, setAggregations] = useState({'categories': Array(5).fill({})});

    function resetProducts() {
        setProducts(Array(32).fill({}));
        setResultCount('-')
    }
    function buildFilterString(f) {
        let result = ''
        for (const [key, value] of Object.entries(f)) {
            result += '&'+key+'='+value;
        }
        return result
    }

    const sendQuery = useCallback(async () => {
        let queryId = query + buildFilterString(filters);
        if (lastQueryId === queryId && page > totalPages) {
            // We're at the end, don't send more requests.
            return;
        }
        try {
            await setLoading(true);
            await setError(false);
            const response = await ky.get(query + '&p=' + (page-1) + buildFilterString(filters), {
                headers: {
                    'x-vintagio-session-id': cookies.userId
                }}).json();
            if (page > 1) {
                await setProducts((prev) => [...prev, ...response.items]);
            } else {
                await setProducts((prev) => [...response.items]);
            }
            if (response.aggregations && response.aggregations !== aggregations) {
                await setAggregations(response.aggregations);
            }
            await setLastQueryId(queryId);
            await setTotalPages(response.total_pages);
            await setResultCount(response.total_results);
            setLoading(false);
        } catch (err) {
            setError(err);
        }
    }, [query, page, filters]);

    useEffect(() => {
        if (query !== '') {
            sendQuery(query);
        }
    }, [query, sendQuery, page, filters]);

    return { loading, error, products, aggregations, resultCount, totalPages, resetProducts};
}

export default useProductFetch;