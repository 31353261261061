import './PopDownHeader.scss'
import Header from "./Header";

function PopDownHeader(props) {
    return (
        <div className="PopDownHeader-container">
            <Header isHero={false} isPopDown={true} />
        </div>
    );
}

export default PopDownHeader;
