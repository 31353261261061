import './ResultsPage.scss';
import {useParams} from "react-router-dom";
import {useContext} from "react";
import {MetadataContext} from "../App";
import ResultsPage from "./ResultsPage";
import {useGenderPaddedTitle} from "../components/useTitle";
import {genderFromURL, getParentCategory} from "../components/utils";


function RecentResults(props) {
    const {metadata} = useContext(MetadataContext);
    let { category_id } = useParams();

    let pageTitle = "Recently Added";
    let gender = genderFromURL();
    let popcornLinks = [
        {url: '/'+gender+'/recent', text: 'Recently Added'}
    ];
    let subCategories = [];
    let query = `https://api.vintag.io/recent?`;
    if (gender !== 'unisex') {
        query += `gender=${gender}`
        popcornLinks.unshift({url: '/'+gender, text: gender[0].toUpperCase() + gender.slice(1)});
    }
    if (category_id) {
        query += `&category_id=${category_id}`;
        let genderGroup = metadata.taxonomy[gender];
        if (genderGroup) {
            let category = genderGroup[category_id];
            subCategories = category.children;
            pageTitle += " " + category.name;

            let parentCat = getParentCategory(genderGroup, category_id);
            if (parentCat) {
                popcornLinks.push({url: '/'+gender+'/recent/'+parentCat.id, text: parentCat.name})
            }
            popcornLinks.push({url: '/'+gender+'/recent/'+category_id, text: category.name})
        }
    } else {
        let genderGroup = metadata[gender+'Menu'];
        if (genderGroup && genderGroup.categories) {
            subCategories = genderGroup.categories
        }
    }

    useGenderPaddedTitle(pageTitle, gender);
    return (
        <ResultsPage pageTitle={pageTitle} popcornLinks={popcornLinks} query={query} gender={gender}
                     decades={metadata.menMenu.decades} categoryPath={gender+"/recent"} subCategories={subCategories} />
    );
}

export default RecentResults;

