import React, {useCallback, useEffect, useRef, useState} from "react";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import left_chevron from "../svg/chevron_left.svg";
import right_chevron from "../svg/chevron_right.svg";
import {useCookies} from "react-cookie";
import ky from "ky";

function ProductImages(props) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    const [cookies, setCookie] = useCookies(['userId']);
    const [flickingIndex, setFlickingIndex] = useState(0);
    const [lastClickedIndex, setLastClickedIndex] = useState(-1);
    const [isFlickingMoving, setFlickingMoving] = useState(false);
    const [leftArrowHidden, setLeftArrowHidden] = useState(true);
    const [rightArrowHidden, setRightArrowHidden] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const container = useRef();
    const flicking = useRef();

    function scrollLeft() {
        if (isFlickingMoving) {
            return;
        }
        flicking.current.prev();
    }

    function scrollRight() {
        if (isFlickingMoving) {
            return;
        }
        flicking.current.next();
    }

    function goToIndex(index) {
        if (isFlickingMoving) {
            return;
        }
        flicking.current.moveTo(index);
        setLastClickedIndex(index);
    }

    function getBestIndex(sources) {
        if (sources.length === 1) {
            return 0;
        }
        let minSize = 0.95 * (container.current.offsetWidth * window.devicePixelRatio);
        let index = -1;
        let bestSize = -1;
        for (let i = 0; i < sources.length; i++) {
            if (!sources[i].width && index < 0) {
                index = i;
                bestSize = minSize * 2;
                continue;
            }
            if (sources[i].width) {
                let newWidth = sources[i].width;
                if (bestSize > minSize && newWidth > minSize && newWidth < bestSize) {
                    index = i;
                    bestSize = newWidth;
                    continue;
                }
                if (bestSize < minSize && newWidth > bestSize) {
                    index = i;
                    bestSize = newWidth
                }
            }
        }
        if (index < 0) {
            index = 0;
        }
        return index;
    }

    useEffect(() => {
        if (props && props.images && props.images.length && props.images[0].sources && props.images[0].sources.length) {
            setIsLoading(false);
        }
    }, [props]);

    function logImageView(productId) {
        ky.get('https://api.vintag.io/log?event=image_view&id='+productId, {
            headers: {
                'x-vintagio-session-id': cookies.userId
            }});
        gtag('event', 'imageView', {
            'send_to': 'G-Y8ZMS72663',
            'event_callback': function() {}
        });
    }

    return (
        <>
            <div ref={container} className={"ProductImageSwipeContainer"}>
                <Flicking
                    ref={flicking}
                    align="center"
                    circular={false}
                    onMoveStart={e => {
                        setFlickingMoving(true);
                        setLastClickedIndex(-1);
                    }}
                    onMoveEnd={e => {
                        setFlickingMoving(false);
                    }}
                    onChanged={e => {
                        setFlickingIndex(e.index);
                        setRightArrowHidden(e.index === props.images.length - 1);
                        setLeftArrowHidden(e.index === 0);
                        if (props.productId) {
                            logImageView(props.productId);
                        }
                    }}>
                    {!isLoading && props.images.map((img, index) => (
                        <div key={index} className={"FlickerImageContainer"}>
                            <img src={img.sources[getBestIndex(img.sources)].url}/>
                        </div>
                    ))}
                </Flicking>
                {!isLoading &&  (
                    <>
                        <img className={`Carousel-chevron left ${leftArrowHidden ? 'hidden' : ''}`} onClick={scrollLeft}
                             src={left_chevron}/>
                        <img className={`Carousel-chevron right ${rightArrowHidden ? 'hidden' : ''}`} onClick={scrollRight}
                             src={right_chevron}/>
                    </>
                )}
            </div>
            <div className={"FlickingDotContainer"}>
                {!isLoading && props.images.map((img, index) => (
                    <div key={index}
                         className={`FlickingDot ${flickingIndex === index && !isFlickingMoving ? 'active' : ''}`}/>
                ))}
            </div>
            <div className={"ImageSelectionContainer"}>
                <ul>
                    {!isLoading && props.images.map((img, index) => (
                        <li key={index}>
                            <div className={"spacer"}/>
                            <button className={(!isFlickingMoving && flickingIndex === index) || (lastClickedIndex === index && isFlickingMoving) ? 'active' : ''}
                                    style={{backgroundImage: `url('${img.sources[0].url}')`}} onClick={() => {
                                goToIndex(index);
                            }}/>
                        </li>
                    ))}
                </ul>
            </div>

        </>
    );

}

export default ProductImages;