import Header from "../components/Header";
import {useEffect, useState, useCallback, useRef} from "react";
import {useLocation, useParams} from 'react-router-dom';
import Product from "../components/Product";


function ProductDetails(props) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}

    let { product_id } = useParams();
    const location = useLocation();

    gtag('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.pathname,
        page_path: window.location.search,
    });
    gtag('event', 'product_view', {
        product_id: product_id,
    });

    return (
        <>
            <Header isHero={false} />
            <div className={"ProductDetails-container header-width"}>
                <Product product_id={product_id} />
            </div>
            <div className={"ProductFooterSpacer"} />
        </>
    );
}

export default ProductDetails;

