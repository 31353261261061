import './FilterButton.scss';
import filter from "../svg/filter.svg";

function FilterButton(props) {
    return (
        <div className={"FilterButton-filter-container"}>
            <button onClick={props.onClick}><img src={filter} />Filter</button>
        </div>
    );
}

export default FilterButton;
