import './SearchSuggestions.scss';

function SuggestionBox(suggestion) {

    return (
        <a key={suggestion.title} href={suggestion.url}>
            <div className={"SuggestionBox-container"} style={{backgroundImage: `url(${suggestion.img})`}}>
                <div className={"SuggestionBox-dark-overlay"} />
                <h4>{suggestion.title}</h4>
            </div>
        </a>
    );
}

function SearchSuggestions() {
    const suggestions = [
        {title: 'Dresses', img: '/imgs/search_suggestions/dress.webp', url: '/women/dresses'},
        {title: 'Raglan Tees', img: '/imgs/search_suggestions/raglanTees.webp', url: '/men/raglan-tees'},
        {title: 'Cottagecore', img: '/imgs/search_suggestions/cottagecore.webp', url: '/search?q=cottagecore'},
        {title: 'Men\'s Shorts', img: '/imgs/search_suggestions/mensShorts.jpg', url: '/men/shorts'},
        {title: '80s Adidas', img: '/imgs/search_suggestions/eightiesAdidas.jpg', url: '/search?q=adidas&decades=1980'},
        {title: 'Tie Dye', img: '/imgs/search_suggestions/tieDye.webp', url: '/search?q=tie+dye'},
        {title: 'Combat Boots', img: '/imgs/search_suggestions/combatBoots.jpeg', url: '/search?q=Combat+boots'},
        {title: 'Red 50s Skirt', img: '/imgs/search_suggestions/fiftiesRedSkirt.jpg', url: '/search?q=red+skirt&decades=1950'},
        {title: 'Windbreakers', img: '/imgs/search_suggestions/windbreakers.jpeg', url: '/search?q=windbreaker'},
        {title: 'Band Tees', img: '/imgs/search_suggestions/bandTees.webp', url: '/search?q=band+tee'},
        {title: '70s Denim', img: '/imgs/search_suggestions/seventiesDenim.webp', url: '/search?q=denim&decades=1970'},
    ];
    return (
        <div className={"SearchSuggestions-bg-bar"}>
            <div className="SearchSuggestions-container header-width">
                <div className={"SearchSuggestions-suggestions"}>
                    {suggestions.map(function(suggestion){
                        return SuggestionBox(suggestion);
                    })}
                </div>
            </div>
        </div>
    );
}

export default SearchSuggestions;
