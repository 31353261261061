import Header from "../components/Header";
import './ResultsPage.scss';
import FilterButton from "../components/FilterButton";
import ResultsTitleBar from "../components/ResultsTitleBar";
import {useEffect, useState, useCallback, useRef} from "react";
import ProductList from "../components/ProductList";
import FilterList from "../components/FilterList";
import useProductFetch from "../components/useProductFetch";
import { useLocation } from 'react-router-dom';
import PopDownHeader from "../components/PopDownHeader";


function ResultsPage(props) {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}

    const location = useLocation()
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState(extractFiltersFromParams());
    const [filterMenuClass, setFilterMenuClass] = useState('inactive');
    const { loading, error, products, aggregations, resultCount, totalPages, resetProducts } = useProductFetch(query, page, filters);
    const loader = useRef(null);

    function ShowFilterMenu() {
        setFilterMenuClass('active');
    }
    function HideFilterMenu() {
        setFilterMenuClass('inactive');
    }
    function onFilterUpdate(filters) {
        setPage(1);
        setFilters(filters);
        let resultBar = document.getElementsByClassName('ResultsTitleBar-Bar');
        window.scrollTo({
            top: resultBar.length > 0 ? resultBar[0].offsetTop : 0,
            behavior: "smooth",
        });
    }
    function extractFiltersFromParams() {
        const urlParams = new URLSearchParams(window.location.search);
        let f = {};
        urlParams.forEach(function(value, key) {
            f[key] = value;
        });
        return f;
    }

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setPage((prev) => prev + 1);
        }
    }, []);

    useEffect(() => {
        resetProducts();
        setPage(1);
        setQuery(props.query);
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
        gtag('event', 'page_view', {
            page_title: document.title,
            page_location: window.location.pathname,
            page_path: window.location.search,
        });
    }, [props.query, handleObserver, location]);
    return (
        <>
            <FilterList
                aggregations={aggregations}
                fixedFilters={props.fixedFilters}
                containerClass={filterMenuClass}
                updateOnClose={true}
                onFilterUpdate={onFilterUpdate}
                onCloseClicked={HideFilterMenu}
                gender={props.gender}
                categoryPath={props.categoryPath}
                subCategories={props.subCategories}
                decades={props.decades}/>
            <Header isHero={false} />
            <FilterButton onClick={ShowFilterMenu} />
            <ResultsTitleBar pageName={props.pageTitle} resultCount={resultCount} popcornLinks={props.popcornLinks} onFilterClick={ShowFilterMenu} />
            <div className={"Results-filter-products-container header-width"}>
                <FilterList
                    aggregations={aggregations}
                    fixedFilters={props.fixedFilters}
                    containerClass={"column"}
                    updateOnClose={false}
                    onFilterUpdate={onFilterUpdate}
                    gender={props.gender}
                    categoryPath={props.categoryPath}
                    subCategories={props.subCategories}
                    decades={props.decades} />
                <ProductList products={products} resultCount={resultCount} isLoading={loading} />
            </div>
            <PopDownHeader />
            <div ref={loader} />
        </>
    );
}

export default ResultsPage;

